import { useState, useEffect, useRef } from "react";
import ImageCarousel from "./ImageCarousel"; // Import your carousel component
import './ImageOverlay.css';

const ImageOverlay = ({ images, selectedWorkPlace }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [carouselWidth, setCarouselWidth] = useState("auto");
    const [isLoading, setIsLoading] = useState(true);
    const carouselRef = useRef(null);
    images = selectedWorkPlace.images;

    const openOverlay = () => {
        setIsOpen(true);
        setIsLoading(true); // Start loading state when opening overlay
        document.body.classList.add("no-scroll");
        document.body.style.overflow = "hidden"; // Disable scrolling
    };

    const closeOverlay = () => {
        setIsOpen(false);
        document.body.classList.remove("no-scroll");
        document.body.style.overflow = ""; // Re-enable scrolling
    };

    useEffect(() => {
        if (isOpen) {
            const checkWidth = () => {
                if (carouselRef.current && carouselRef.current.offsetWidth > 50) {
                    setCarouselWidth(`${carouselRef.current.offsetWidth}px`);
                    console.log("Carousel width:", carouselRef.current.offsetWidth);
                } else {
                    requestAnimationFrame(checkWidth); // Retry until it's loaded
                }
            };
    
            requestAnimationFrame(checkWidth);
        }
    
        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);
    return (
        <>
            {/* View Images Button */}
            <div className="view-images-container">
                <button className="view-images-btn" onClick={openOverlay}>
                    View Images
                </button>
            </div>
            {/* Overlay */}
            {isOpen && (
                <div className="image-overlay" onClick={closeOverlay}>
                    <div className="image-overlay-content" onClick={(e) => e.stopPropagation()}>
                        <h3 style={{ maxWidth: carouselWidth }}>{selectedWorkPlace.name}</h3>
                        {images.length === 0 && (
                            <p>No images uploaded for this workplace</p>
                        )}
                        {images.length > 0 && (
                            <div className="image-carousel-container" ref={carouselRef} >
                                <ImageCarousel images={images}/>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageOverlay;