import { useState, useEffect } from "react";
import './ImageCarousel.css';

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!hasLoaded) {
      setLoading(true); // Only set loading if images have never been loaded before
    }
  }, [currentIndex, hasLoaded]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={"image-carousel"}>
      {loading && !hasLoaded && <div className="carousel-loading-spinner"></div>}

      <div className={`carousel-container ${loading ? "hidden" : ""}`}>
        <button className="carousel-button left" onClick={handlePrevious}>
          &#8249;
        </button>

        {/* Show loading spinner until the image is loaded */}

        <img
          className={`carousel-image`}
          src={images[currentIndex]}
          alt="No images uploaded for this workplace"
          onLoad={() => {
            setLoading(false);
            if (currentIndex === images.length - 1) {
              setHasLoaded(true);
            } // Mark images as loaded forever
          }} // Set loading to false when image is fully loaded
        />

        <button className="carousel-button right" onClick={handleNext}>
          &#8250;
        </button>

        <div className="carousel-counter">
          {currentIndex + 1} / {images.length}
        </div>
      </div>
      
    </div>
  );
};

export default ImageCarousel;