import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getWorkplaces = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/workplaces`);
    return response.data;
  } catch (error) {
    //console.error("Error fetching workplaces:", error);
    throw error;
  }
};

export const addNewWorkplace = async (workplace) => {
  try {
    //console.log(workplace);
    const response = await axios.post(`${API_BASE_URL}/workplaces`, workplace);
    return response.data;
  } catch (error) {
    //console.error("Error adding workplace:", error);
    throw error;
  }
};

export const addReview = async (workplaceId, reviewData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/workplaces/${workplaceId}/add-review`,
      reviewData
    );
    return response.data;
  } catch (error) {
    //console.error("Error adding review:", error);
    throw error;
  }
};

export const uploadImages = async (workplaceId, images) => {
  try {
    // Iterate over the files array
    const uploadedImages = [];
    for (let i = 0; i < images.length; i++) {
      const file = images[i];

      // Upload each image to Cloudinary
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
      formData.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);

      // Send the request to Cloudinary
      const response = await fetch("https://api.cloudinary.com/v1_1/" + process.env.REACT_APP_CLOUDINARY_CLOUD_NAME + "/image/upload", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.secure_url) {
        uploadedImages.push(data.secure_url); // Save the secure URL of each uploaded image
      }
    }

    // After uploading all images, you can handle the URLs however you need
    //console.log("Uploaded images:", uploadedImages);

    await fetch(`${API_BASE_URL}/workplaces/${workplaceId}/upload-images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageurls: uploadedImages }),
    });
  } catch (error) {
    //console.error("Error adding review:", error);
    throw error;
  }
};

export const getCoordinatesService = async (address) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/coordinates`, {
      params: { address },
    });

    return response.data;
  } catch (error) {
    //console.error("Error adding review:", error);
    throw error;
  }
};

export const getAddressSuggestions = async (query) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/address-suggestions`, {
      params: { query },
    });

    return response.data;
  } catch (error) {
    //console.error("Error getting address suggestions:", error);
    throw error;
  }
};

export const getFilteredWorkplaces = async (filtersData) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/filtered-workplaces`, {
      params: filtersData,
    });

    return response.data;
  } catch (error) {
    //console.error("Error getting address suggestions:", error);
    throw error;
  }
};